import React from 'react';
import { graphql, PageRendererProps, navigate } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '../components/seo';

import * as styles from './referral.module.scss';

import useIsReferralCodeValid from '../hooks/useIsReferralCodeValid.hook';
import InvalidReferralCodeDialog from '@components/invalidReferralCodeDialog';
import Layout from '@src/components/Layout/Layout';
import TextHero from '@src/components/TextHero';
import MinimalNavbar from '@src/components/MinimalNavbar/MinimalNavbar';
import MinimalFooter from '@src/components/MinimalFooter/MinimalFooter';
import ReferralSmsForm from '@src/components/ReferralSmsForm/ReferralSmsForm';
import {
  ContentfulImageList,
  ContentfulTextSection,
} from '../../graphql-types';
import { ContentfulCardsSectionComponent } from '../../graphql-types';
import Container from '@src/components/Container';
import CtaCards from '@components/CtaCards';
import Markdown from '@src/components/markdown';
import TextArticle from '@src/components/TextArticle/TextArticle';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import { GatsbyImage } from 'gatsby-plugin-image';
import useHasMounted from '@src/lib/useHasMounted';
import classNames from 'classnames';
import { AppDownloadUrl } from '@src/pageConstants';

type CardReferralProps = {
  data: any;
} & PageRendererProps;

const CardReferral = ({
  data,
  location: { pathname },
  ...restProps
}: CardReferralProps) => {
  const referralCode = restProps['*'];

  if (!referralCode) {
    navigate('/404', { replace: true });
    return null;
  }

  const { isValid, isLoading } = useIsReferralCodeValid(referralCode);
  const showCodeError = !isValid && !isLoading;

  const mounted = useHasMounted();

  const {
    headLine,
    pageHeader,
    pageHeaderDescription: { pageHeaderDescription },
    pageContent,
    questions,
  } = data.contentfulPage;

  const helpText = pageContent[0] as ContentfulTextSection;
  const signupSteps = pageContent[1] as ContentfulCardsSectionComponent;
  const ReferralPageContent = pageContent[2];
  const { title, mainText, campaignTerms, referralSteps } = ReferralPageContent;

  const imageList = pageContent[3] as ContentfulImageList;

  const img1 = useContentfulImage(imageList.images[0]);
  const img2 = useContentfulImage(imageList.images[1]);

  const footerContent = (
    <>
      <div style={{ paddingBottom: 60 }}>
        <ReferralSmsForm referralCode={referralCode} />
      </div>
      <MinimalFooter />
    </>
  );

  return (
    <Layout
      customNavbar={<MinimalNavbar />}
      customFooter={footerContent}
      questions={questions}
      theme="soft"
    >
      {showCodeError && <InvalidReferralCodeDialog />}
      <SEO
        url={`https://rocker.com${pathname}`}
        title={headLine}
        description="Ladda ner Rocker-appen och få cashback på dina köp."
        noIndex
      />
      <TextHero
        theme="soft"
        title={pageHeader}
        subtitle={pageHeaderDescription}
        boldSubtitle
        style={{ position: 'relative' }}
      >
        <ReferralSmsForm referralCode={referralCode} />
        <div
          className={classNames({
            [styles.imgWrapper]: true,
            [styles.mounted]: mounted,
          })}
        >
          <GatsbyImage
            className={styles.img1}
            alt={img1.title}
            image={img1.imageData}
          />
          <div className={styles.img2Wrapper}>
            <GatsbyImage
              className={styles.img2}
              alt={img2.title}
              image={img2.imageData}
            />
          </div>
        </div>
      </TextHero>
      <div style={{ backgroundColor: 'white' }}>
        <Container style={{ paddingTop: 80, paddingBottom: 1 }}>
          <TextArticle subheading={helpText.title} style={{ marginBottom: 80 }}>
            {helpText.content.content}
          </TextArticle>
          <CtaCards cards={signupSteps.card} />
        </Container>
      </div>
      {/*<Header
        logoUrl={Logo}
        headerTitle={pageHeader}
        headerSubtitle={pageHeaderDescription}
        textTitle={title}
        mainText={mainText.mainText}
        referralCode={referralCode}
        referralSteps={referralSteps}
        termsText={campaignTerms.campaignTerms}
        newCampaignStyle
      />
      <LoanFeatures
        title={cardFeaturesTitle}
        loanFeatures={cardFeatures}
        isWhite
        newStyle
        pay
  />*/}
    </Layout>
  );
};

CardReferral.propTypes = {
  data: propTypes.object,
  location: propTypes.object,
};

export default CardReferral;

export const query = graphql`
  query CardReferralPageQuery {
    contentfulPage(pageUrl: { eq: "/referral-new" }) {
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      questions {
        answer {
          answer
        }
        question
      }
      pageContent {
        ... on ContentfulCardReferralContent {
          campaignTerms {
            campaignTerms
          }
          title
          mainText {
            mainText
          }
          referralSteps {
            icon {
              title
              file {
                url
              }
            }
            extraText
            title
            text
            longText {
              longText
            }
          }
        }
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulFeature {
          icon {
            file {
              url
            }
          }
        }
        ... on ContentfulTextSection {
          title
          content {
            content
          }
        }
        ... on ContentfulFeatures {
          title
          feature {
            title
            childContentfulFeatureDescriptionTextNode {
              description
            }
            icon {
              file {
                url
              }
              title
            }
          }
        }
        ... on ContentfulImageList {
          id
          name
          images {
            gatsbyImageData(width: 324)
            title
          }
        }
      }
    }
  }
`;
